body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app{
  width: 100vw;
  height: 100vh;
  color: white;

  /* background-image: url("./2.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg1{
  background-image: url(/static/media/1.3c14f10e.jpg);
}

.bg2{
  background-image: url(/static/media/2.29b2dbb8.jpg);
}

.bg3{
  background-image: url(/static/media/3.b1035445.jpg);
}

.bg4{
  background-image: url(/static/media/4.d6fae6f3.jpg);
}

.bg5{
  background-image: url(/static/media/5.d41b4201.jpg);
}

.bg6{
  background-image: url(/static/media/6.91cd7648.jpg);
}

.bg7{
  background-image: url(/static/media/7.2fd76c98.jpg);
}

.bg8{
  background-image: url(/static/media/8.13c7dd9d.jpg);
}

.bg9{
  background-image: url(/static/media/9.f3b72508.jpg);
}

.bg10{
  background-image: url(/static/media/10.7dfe67e2.jpg);
}

.bg11{
  background-image: url(/static/media/11.3e234d5b.jpg);
}

body{
}

.no-link{
  color: white;
  text-decoration: none;
}

.app > .app-container > .content > .center{
  padding: 0px 50px;
}


.top-bar {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    padding-top: 15px;
}

.top-bar>* {
    display: flex;
}

.top-bar .center,
.right {
    padding-top: 15px;
}

.top-bar .item {
    font-size: 20px;
    font-weight: 600;
}

.top-bar .center .item {
    margin: 0px 15px;
}

.top-bar .right .logo {
    width: 50px;
    height: 50px;
}

.top-bar .center {
    padding-top: 20px;
}

@media only screen and (max-width: 1111px) {
    .title h1{
        font-size: 25px;
    }
}

@media only screen and (max-width: 784px) {

}

@media only screen and (max-width: 500px) {
    .title h1{
        font-size: 20px;
    }
}
.slide>.top {
    height: 270px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    /*background-color: red;*/
    position: relative;
}

.slide .content {
    /*background-color: green;*/
    position: absolute;
    bottom: 0;
    height: -webkit-min-content;
    height: min-content;
}

.slide .top-bottom {
    position: fixed;
    bottom: 70px;
}

.slide .bottom-bottom {
    position: fixed;
    bottom: 70px;
}

.slide .bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 30px;
}

.slide>.bar .input {
    margin-right: 20px;
    border: none;
    padding: 3px;
    border-radius: 3px;
    padding-left: 10px;
    font-size: 20px;
}

.slide>.bar .button {
    border: none;
    padding: 3px 20px;
    border-radius: 3px;
    font-weight: 400;
    font-size: 20px;
}

.slide>.bar .button:hover {
    cursor: pointer;
}

p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
            hyphens: auto;
    padding-right: 80px;
    color: rgb(255, 247, 212);
    font-weight: 500;
    line-height: 40px;
}

p > span{
    background-color: rgba(0, 0, 0, 0.55);
    padding: 5px 5px;
}

.small-note{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-style: italic;
}

@media only screen and (max-width: 1111px) {
    .slide>.top {
        height: 270px;
        font-size: 18px;
        padding-right: 25px;
    }

    p{
        line-height: 35px;
    }

    .slide>.bar .input {
        font-size: 18px;
    }

    .slide>.bar .button {
        font-size: 18px;
    }
}

@media only screen and (max-width: 784px) {
    .slide>.top {
        height: 270px;
        font-size: 15px;
        padding-right: 25px;
    }

    .slide>.bar .input {
        font-size: 15px;
    }

    .slide>.bar .button {
        font-size: 15px;
    }

    p{
        line-height: 30px;
    }

    p > span{
        padding: 0px 5px;
    }
}

@media only screen and (max-width: 500px) {
    .slide>.top {
        height: 270px;
        font-size: 12px;
        padding-right: 25px;
        bottom: 100px;
    }

    .slide>.bar .input {
        font-size: 12px;
        margin-right: 0;
        margin-bottom: 20px;
        flex: 1 1;
    }

    .slide>.bar .button {
        font-size: 12px;
        flex: 1 1;
    }

    .slide >.bar > center{
        display: flex;
        flex-direction: column;
        width: 80%;
    }

    p{
        line-height: 30px;
    }

}
