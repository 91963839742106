.top-bar {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    padding-top: 15px;
}

.top-bar>* {
    display: flex;
}

.top-bar .center,
.right {
    padding-top: 15px;
}

.top-bar .item {
    font-size: 20px;
    font-weight: 600;
}

.top-bar .center .item {
    margin: 0px 15px;
}

.top-bar .right .logo {
    width: 50px;
    height: 50px;
}

.top-bar .center {
    padding-top: 20px;
}

@media only screen and (max-width: 1111px) {
    .title h1{
        font-size: 25px;
    }
}

@media only screen and (max-width: 784px) {

}

@media only screen and (max-width: 500px) {
    .title h1{
        font-size: 20px;
    }
}