.app{
  width: 100vw;
  height: 100vh;
  color: white;

  /* background-image: url("./2.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg1{
  background-image: url("./1.jpg");
}

.bg2{
  background-image: url("./2.jpg");
}

.bg3{
  background-image: url("./3.jpg");
}

.bg4{
  background-image: url("./4.jpg");
}

.bg5{
  background-image: url("./5.jpg");
}

.bg6{
  background-image: url("./6.jpg");
}

.bg7{
  background-image: url("./7.jpg");
}

.bg8{
  background-image: url("./8.jpg");
}

.bg9{
  background-image: url("./9.jpg");
}

.bg10{
  background-image: url("./10.jpg");
}

.bg11{
  background-image: url("./11.jpg");
}

body{
}

.no-link{
  color: white;
  text-decoration: none;
}

.app > .app-container > .content > .center{
  padding: 0px 50px;
}

