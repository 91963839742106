.slide>.top {
    height: 270px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    /*background-color: red;*/
    position: relative;
}

.slide .content {
    /*background-color: green;*/
    position: absolute;
    bottom: 0;
    height: min-content;
}

.slide .top-bottom {
    position: fixed;
    bottom: 70px;
}

.slide .bottom-bottom {
    position: fixed;
    bottom: 70px;
}

.slide .bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 30px;
}

.slide>.bar .input {
    margin-right: 20px;
    border: none;
    padding: 3px;
    border-radius: 3px;
    padding-left: 10px;
    font-size: 20px;
}

.slide>.bar .button {
    border: none;
    padding: 3px 20px;
    border-radius: 3px;
    font-weight: 400;
    font-size: 20px;
}

.slide>.bar .button:hover {
    cursor: pointer;
}

p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    padding-right: 80px;
    color: rgb(255, 247, 212);
    font-weight: 500;
    line-height: 40px;
}

p > span{
    background-color: rgba(0, 0, 0, 0.55);
    padding: 5px 5px;
}

.small-note{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-style: italic;
}

@media only screen and (max-width: 1111px) {
    .slide>.top {
        height: 270px;
        font-size: 18px;
        padding-right: 25px;
    }

    p{
        line-height: 35px;
    }

    .slide>.bar .input {
        font-size: 18px;
    }

    .slide>.bar .button {
        font-size: 18px;
    }
}

@media only screen and (max-width: 784px) {
    .slide>.top {
        height: 270px;
        font-size: 15px;
        padding-right: 25px;
    }

    .slide>.bar .input {
        font-size: 15px;
    }

    .slide>.bar .button {
        font-size: 15px;
    }

    p{
        line-height: 30px;
    }

    p > span{
        padding: 0px 5px;
    }
}

@media only screen and (max-width: 500px) {
    .slide>.top {
        height: 270px;
        font-size: 12px;
        padding-right: 25px;
        bottom: 100px;
    }

    .slide>.bar .input {
        font-size: 12px;
        margin-right: 0;
        margin-bottom: 20px;
        flex: 1;
    }

    .slide>.bar .button {
        font-size: 12px;
        flex: 1;
    }

    .slide >.bar > center{
        display: flex;
        flex-direction: column;
        width: 80%;
    }

    p{
        line-height: 30px;
    }

}